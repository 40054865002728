<template>
    <div>Logging out..</div>
</template>

<script>
import UserService from '../service/UserService';
export default {
    userService: null,
    created() {
        this.userService = new UserService();
    },
    mounted() {
        this.userService.logoutUser().then((data) => this.$router.push("/login"));
    }
}
</script>
